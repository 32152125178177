import React from 'react';
import './Modal.css'; // You can create a CSS file for styling
import ContactUs from "../../Pages/MainPages/ContactUs/ContactUs";

const Modal = ({ showModal, closeModal, selectedItems }) => {
    return (
        <>
            {
                showModal && (
                    <div className={`modal ${showModal ? "show" : ""}`}>
                        <div className="modal-content">{<ContactUs selectedItems={selectedItems}/>}</div>
                        <div className="modal-overlay" onClick={closeModal}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default Modal;