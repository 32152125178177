import React, {useEffect} from 'react';
import './Navbar.css';
import {Link, Outlet} from "react-router-dom";
import SocialMediaNavbar from "../SocialMediaNavbar/SocialMediaNavbar";
import FAQs from "../SocialMediaNavbar/FAQs";

const imgRelativePath = `${process.env.PUBLIC_URL}/Assets/Images`

const Navbar = () => {
    const handleClick = (e) => {
        if (window.innerWidth <= 1400) {
            // console.log("clicked");
            const btn_element = document.getElementById("navbar-toggle");
            const nav_items = document.getElementById('navbarSupportedContent');

            btn_element.setAttribute("class", "navbar-toggler collapsed");
            btn_element.setAttribute("aria-expanded", "false");
            nav_items.setAttribute("class", "collapse navbar-collapse navbar-scroll");
        }
    };

    useEffect(() => {
        // Replace placeholder with your actual condition to determine homepage
        const navbar = document.getElementById('navbar');

        const handleScroll = () => {
            const scrollY = window.scrollY;

            if (scrollY > 0) {
                navbar.classList.add('scrolled');
            } else {
                navbar.classList.remove('scrolled');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    return (
        <React.Fragment>
            <nav id="navbar" className="navbar navbar-expand-xxl py-3">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/" onClick={handleClick}>
                        <img src={imgRelativePath + "/logo.png"} width={"50px"} alt="White Flag Coaching Logo"/>
                        White Flag Coaching
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation" id="navbar-toggle">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse navbar-scroll" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="/individual" onClick={handleClick} className="nav-link">Personal Coaching</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/relationships" onClick={handleClick}  className="nav-link">Relationships</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/groups" onClick={handleClick} className="nav-link">Groups</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/businesses" onClick={handleClick} className="nav-link">Businesses</Link>
                            </li>
                            <li className="nav-item ms-auto">
                                <Link className="nav-link" onClick={handleClick} to={"/"}>Home</Link>
                            </li>
                            {/*<li className="nav-item">*/}
                            {/*    <Link className="nav-link" to="/about-us">About us</Link>*/}
                            {/*</li>*/}
                            <li className="nav-item">
                                <Link className="nav-link" onClick={handleClick}  to="/contact-us">Contact Us</Link>
                            </li>
                        </ul>
                        <FAQs className="show-socials hide-socials-nav greater"/>
                        <SocialMediaNavbar className="show-socials hide-socials-nav"/>
                    </div>
                </div>
            </nav>
            <Outlet/>
        </React.Fragment>
    )
}

export default Navbar;