import React, {useRef, useState} from "react";
import './Contact.css'
import axios from "axios";
import Input from "./Inputs/Input";
import {
    VALIDATOR_REQUIRE,
    VALIDATOR_EMAIL
} from '../util/validators';
import {useForm} from "../hooks/form-hook";

const ContactPage = ({selectedItems}) => {

    const contactFormRef = useRef(null);
    const [selectedRole, setSelectedRole] = useState('Individual');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const [formState, inputHandler, setFormData] = useForm(
        {
            name: {
                value: '',
                isValid: false
            },
            emailAddress: {
                value: '',
                isValid: false
            },
            phoneNumber: {
                value: '',
                isValid: false
            },
            formMessage: {
                value: '',
                isValid: false
            },
            coachingSessions: {
                value: selectedRole,
                isValid: false
            },
            selectedItems: {
                value: selectedItems,
                isValid: true
            }
        },
        false
    );

    const today = new Date(); // Get today's date

    // Function to format the date with leading zeros
    const formatDate = (date) => {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // Jan = 0, so add 1
        const yyyy = date.getFullYear();
        return `${dd}-${mm}-${yyyy}`;
    };

    const formattedDate = formatDate(today);

    const formatEmailBody = (formState) => {
        const emailMessage =  formState.inputs.formMessage.value.trim().length > 0 ? formState.inputs.formMessage.value : `No message entered by ${formState.inputs.name.value}`;
        const symptomsMessage = formState.inputs.selectedItems.value.length > 0 ?  formState.inputs.selectedItems.value.map((item) => `- ${item}\n\t   `) : `No symptoms selected by ${formState.inputs.name.value}`;

        return `
            Hi White Flag, I trust you well.
            
            Please reach out to me.
            
            See the below information:
            
            Name: ${formState.inputs.name.value}
            Email: ${formState.inputs.emailAddress.value}
            Phone Number: ${formState.inputs.phoneNumber.value}
            
            Coaching sessions are for: ${formState.inputs.coachingSessions.value}
            
            Additional Information:
            
            ${emailMessage}
            
            Symptoms and Conditions:
            ${symptomsMessage}
        `;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/send-email`, {
                subject: `Contact form - White Flag Coaching - ${formattedDate}`,
                message: formatEmailBody(formState)
            });
            setSuccess(response.data.message);
        } catch (error) {
            console.error(error);
            setError('Error sending email');
        } finally {
            setIsLoading(false);
            setSelectedRole('Individual')
            setFormData(
                {
                    name: {
                        value: '',
                        isValid: false
                    },
                    emailAddress: {
                        value: '',
                        isValid: false
                    },
                    phoneNumber: {
                        value: '',
                        isValid: false
                    },
                    formMessage: {
                        value: '',
                        isValid: false
                    },
                    coachingSessions: {
                        value: selectedRole,
                        isValid: false
                    },
                    selectedItems: {
                        value: selectedItems,
                        isValid: true
                    }
                },
                false
            )
        }
    };

    // const handleChange = (event) => {
    //     const { id, value } = event.target;
    //     setFormData({ ...formData, [id]: value });
    // };

    return (
        <div className="contact-page-container align-middle">
            <form ref={contactFormRef} onSubmit={handleSubmit} method="post">
                <div className="center-contact-form">
                    <div className="row">
                        <h1>Contact Us</h1>
                    </div>
                    <div className="row">
                        <h4>We'd love to hear from you!</h4>
                    </div>
                    <div className={"row"}>
                        {error && <div className="error">{error}</div>}
                        {success && <div className="success">{success}</div>}
                    </div>
                    <div className="row input-container">
                        <div className="col-xs-12">
                            <Input type="text"
                                   element="text"
                                   className="styled-input wide"
                                   label="Full Name"
                                   id="name"
                                   validators={[VALIDATOR_REQUIRE()]}
                                   errorText="Please enter a valid name."
                                   onInput={inputHandler}/>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input element="text" className="styled-input" label="Email Address" id="emailAddress" validators={[VALIDATOR_EMAIL()]} errorText="Please enter a valid email address." onInput={inputHandler}/>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input element="text" className="styled-input" label="Phone Number" id="phoneNumber" validators={[VALIDATOR_REQUIRE()]} errorText="Please enter a valid phone number." onInput={inputHandler}/>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input element="select" listItems={["Individual", "Relationships", "Groups", "Businesses"]} label="What coaching sessions are you interested in?" id="coachingSessions" validators={[VALIDATOR_REQUIRE()]} errorText="Please select a valid option." onInput={inputHandler}/>
                        </div>
                        <div className="col-xs-12">
                            <Input element="textarea" className="styled-input wide" label="Message" id="formMessage" validators={[]} errorText="" onInput={inputHandler}/>
                        </div>
                        <div className="col-xs-12">
                            <button type="submit" className="btn-lrg submit-btn" disabled={!formState.isValid}>{isLoading ? 'Sending...' : 'Send Email'}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ContactPage;