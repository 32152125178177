import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './Testimonial.css';

const Testimonial = () => {

    const imgRelativePath = `${process.env.PUBLIC_URL}/Assets/Images/Testimonial/`

    const testimonials = [
        {
            name: "Ayanda Khumalo",
            testimonial: "I began my journey with White Flag as I was desperate to meet the woman I knew deep down inside I'm destined to be. Fast forward 2yrs down memory lane from a shy & confused woman. I'm walking boldly in the direction of my life purpose and my attitude towards life and success is where I've always dreamt it would at least be and now I look forward to unimaginable opportunities coming my way.... #ANYTHINGISPOSSIBILE   with the right guidance",
            img: `${imgRelativePath}/Ayanda Khumalo.jpeg`
        },
        {
            name: "Lisa Hoon",
            testimonial: "White Flag Coaching has provided me with so much insight, opened my mind, heart and soul to seeing everything that seemed one dimensional from a fresh, new, different perspective. With the support and guidance from Mary I learnt to see myself, my world and the outside world in a totally new light and started to see possibility where I haven't before. I am 100% more positive, loving where I am emotionally, have a renewed confidence in myself and excited for what's to come. Thank you Mary for motivating me, and teaching me a skillset to navigate life!",
            img: `${imgRelativePath}/Lisa Hoon.jpeg`
        },
        {
            name: "Vusumzi Mqoboli",
            testimonial: "Before the coaching with White Flag, l really thought that I was not doing enough with my life in terms of contributing positively to society. As much as I have found my purpose I still felt that I was not doing enough and this put on a lot of pressure on me. The coaching sessions with White Flag totally changed my perspective. I learnt gratitude, self-love and to appreciate the small things in life. I'm now in a better space, as much as I'm am not seeing the change I want to see in my community yet but I know with the little positive contribution I'm making things will change for the better. As people, we sometimes under estimate the influence of little things.",
            img: `${imgRelativePath}/Vusumzi Mqoboli.jpeg`
        },
        {
            name: "Grant Pekeur",
            testimonial: "White Flag is Not just life Coaching... Its A Life Opener.. Thank you Sam and Mary for All the work you did, still do and will still continue to do, not only in my life, but many others. And because of the difference you made in my way of thinking and seeing the world, i can in turn pass that on to others... And so the Positive Ripple effect goes on.. Keep on keeping on..",
            img: `${imgRelativePath}/Grant Pekeur.jpeg`
        },
        {
            name: "Jo Anne Abrahams",
            testimonial: "In a crazy and extremely busy world we are living in today, life coaching has taught me how to look within myself for strength and courage to handle life's challenges with a positive perspective.  Iv gained insight, understanding, confidence and most importantly peace in every area of my life. Home, work and relationships. A freedom to be the best \"you\" you can be. I am forever grateful for endless growth and possibilities.",
            img: `${imgRelativePath}/Jo Anne Abrahams.jpeg`
        },
        {
            name: "Leighton Jacobs",
            testimonial: "White Flag Coaching with Sam & Mary has changed my life. It’s opened my eyes to the small victories in life which we don’t seem to celebrate. And with these victories comes simple changes which ultimately improves our lives. White Flag takes a different approach, giving you the support you need and helping you to become more self-aware. If you want to change your life for the better, contact Sam or Mary.  ",
            img: `${imgRelativePath}/Leighton Jacobs.jpg`
        },
        {
            name: "Nuraan Van Der Schyff",
            testimonial: "In just a year I went from having an anxiety attack because I tried having a meal in public alone to being able to travel on my own. Sam and Mary have made me realise that being alone isnt a weakness... it's a sign if strength.",
            img: `${imgRelativePath}/Nuraan Van Der Schyff.jpeg`
        },
        {
            name: "Robyn-Lee Abrahams",
            testimonial: "My life coach journey has taught me how to manage major life decisions and everyday stresses in a positive way. I have been transformed in my thinking and have obtained useful life skills through white flag coaching.",
            img: `${imgRelativePath}/Robyn-Lee Abrahams.jpeg`
        },
        {
            name: "Kauthar Abrahams",
            testimonial: "I have suffered with a lot of anxiety in the past. Starting my journey with White Flag Coaching 8 months ago, I have discovered things about myself that I didn't even know and it feels amazing being on this journey of self love. White Flag coaching has honestly changed my life and taught me so much! Thank you Mary and Sam.",
            img: `${imgRelativePath}/Kauthar Abrahams.jpeg`
        }
    ]

    return (
        <section className="testimonials">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <OwlCarousel  id="customers-testimonials" loop={true} center={true} items={3} margin={0}
                                     autoplay={true} dots={true} autoplayTimeout={8500} smartSpeed={450}
                                     responsive={{
                                         0: {
                                             items: 1,
                                         },
                                         768: {
                                             items: 2,
                                         },
                                         1170: {
                                             items: 3,
                                         },
                                     }}
                                     className="owl-carousel owl-theme">
                            {
                                testimonials.map((item, index) => {
                                    return (<div key={`Testimonial Number: ${index}`} className="item">
                                        <div className="shadow-effect">
                                            <img className="img-circle"
                                                 src={item.img}
                                                 alt=""/>
                                            <p>"{item.testimonial}"</p>
                                        </div>
                                        <div className="testimonial-name">{item.name}</div>
                                    </div>);
                                })
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonial;

