import React from "react";
import './Homepage.css'
import DynamicCards from "../../../Components/DynamicCards/DynamicCards";
import Contact from "../../../Components/ContactPage/Contact";
import Testimonial from "../../../Components/TestimonialSlider/Testimonial";

const Homepage = () => {
    const vidRelativePath = `${process.env.PUBLIC_URL}/Assets/Videos/`

    return (
        <div id="body-wrapper" className="body-wrapper home">
            <section className="section section1">
                <div className="section-main-content video-container">
                    <video autoPlay loop muted>
                        <source src={vidRelativePath + "White Flag Video.mp4"} type="video/mp4"/>
                    </video>
                </div>
            </section>
            <section className="section section2">
                <DynamicCards/>
            </section>
            <section className="section">
                <Contact selectedItems={[]}/>
            </section>
            <section className="section">
                <Testimonial/>
            </section>
        </div>
    )
};

export default Homepage;