import './App.css';
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Navbar from "./Components/Navbar/Navbar";
import SocialMediaNavbar from "./Components/SocialMediaNavbar/SocialMediaNavbar";
import Footer from "./Components/Footer/Footer";
import NotFound from "./Pages/NotFound/NotFound";

import Homepage from "./Pages/MainPages/Home/Homepage";
import AboutPage from "./Pages/MainPages/AboutPage/AboutPage";
import ContactUs from "./Pages/MainPages/ContactUs/ContactUs";

import IndividualPage from "./Pages/OfferingPages/Individual/IndividualPage";
import RelationshipsPage from "./Pages/OfferingPages/Relationships/RelationshipsPage";
import GroupsPage from "./Pages/OfferingPages/Groups/GroupsPage";
import BusinessesPage from "./Pages/OfferingPages/Businesses/BusinessesPage";
import FAQ from "./Pages/MainPages/FAQ/FAQ";
import FAQs from "./Components/SocialMediaNavbar/FAQs";
import dotenv from "dotenv"

dotenv.config({
  path: ".env"
});

const App = () => {
  return (
      <div className="page-wrapper">
        <BrowserRouter>
          <Navbar/>
          <Routes>
            <Route path="/" element={<Homepage/>}/>
            <Route path="/about-us" element={<AboutPage/>}/>
            <Route path="/contact-us" element={<ContactUs/>}/>
            <Route path="/faq" element={<FAQ/>}/>

            <Route path="/individual" element={<IndividualPage/>}/>
            <Route path="/relationships" element={<RelationshipsPage/>}/>
            <Route path="/groups" element={<GroupsPage/>}/>
            <Route path="/businesses" element={<BusinessesPage/>}/>

            <Route path="*" element={<NotFound/>}/>
          </Routes>
          <SocialMediaNavbar className="hide-socials show-socials-nav"/>
          <FAQs className="hide-socials show-socials-nav"/>
          <Footer/>
        </BrowserRouter>
      </div>
  );
}

export default App;
