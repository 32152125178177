import React from "react";
// import {Link} from "react-router-dom";

import './Footer.css'
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="container">
                <div className="footer-cta pt-5 pb-5">
                    <div className="row">
                        <div className="col-xl-4 col-md-4 mb-30">
                            <div className="single-cta">
                                <i className="fas fa-phone"></i>
                                <div className="cta-text">
                                    <h4>Call us</h4>
                                    <span>072 853 6406</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4 mb-30">
                            <div className="single-cta">
                                <i className="far fa-envelope-open"></i>
                                <div className="cta-text">
                                    <h4>Mail us</h4>
                                    <span>
                                        <Link to="mailto:info@whiteflagcoaching.co.za" rel="noopener noreferrer" target="_blank">info@whiteflagcoaching.co.za</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-content pt-5 pb-5">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 mb-50">
                            <div className="footer-widget">
                                <div className="footer-logo">
                                    <Link to="/"><img src="/Assets/Images/White_Flag_Logo-01%5B1%5D.svg" className="img-fluid" alt="logo"/></Link>
                                </div>
                                <div className="footer-text">
                                    <p>White Flag Coaching. A safe space, where you can find acceptance to express yourself and be heard. An environment that is built on trust, belief and a curiosity that promotes growth ...</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                            <div className="footer-widget">
                                <div className="footer-widget-heading">
                                    <h3>Useful Links</h3>
                                </div>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                    <li><Link to="/about-us">About us</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                            <div className="footer-social-icon">
                                <span>Follow us</span>
                                <Link to="https://www.facebook.com/whiteflagcoaching" target="_blank" rel="noopener noreferrer">
                                    <i className="fa-brands fa-facebook-f"></i>
                                </Link>
                                <Link to="https://www.linkedin.com/in/white-flag-coaching-npc-b33b291b3/?originalSubdomain=za"
                                   target="_blank" rel="noopener noreferrer">
                                    <i className="fa-brands fa-linkedin"></i>
                                </Link>
                                <Link to="https://twitter.com/whiteflagcoach" target="_blank" rel="noopener noreferrer">
                                    <i className="fa-brands fa-twitter"></i>
                                </Link>
                                <Link to="https://www.instagram.com/whiteflag_coaching/" target="_blank" rel="noopener noreferrer">
                                    <i className="fa-brands fa-instagram"></i>
                                </Link>
                                <Link to="https://www.tiktok.com/@whiteflagcoaching" target="_blank" rel="noopener noreferrer">
                                    <i className="fa-brands fa-tiktok"></i>
                                </Link>
                            </div>
                            {/*<div className="footer-widget">*/}
                            {/*    <div className="footer-widget-heading">*/}
                            {/*        <h3>Subscribe</h3>*/}
                            {/*    </div>*/}
                            {/*    <div className="footer-text mb-25">*/}
                            {/*        <p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>*/}
                            {/*    </div>*/}
                            {/*    <div className="subscribe-form">*/}
                            {/*        <form action="#">*/}
                            {/*            <input type="text" placeholder="Email Address"/>*/}
                            {/*                <button><i className="fab fa-telegram-plane"></i></button>*/}
                            {/*        </form>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                            <div className="copyright-text">
                                <p>Copyright &copy; 2023, All Right Reserved <a href="https://codepen.io/anupkumar92/">White
                                    Flag Coaching</a></p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                            <div className="footer-menu">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/about-us">About</Link></li>
                                    <li><Link to="/contact-us">Contact</Link></li>
                                    {/*<li><a href="#">Terms</a></li>*/}
                                    {/*<li><a href="#">Privacy</a></li>*/}
                                    {/*<li><a href="#">Policy</a></li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;