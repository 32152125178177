import React from "react";
import './PageHeading.css';

const PageHeading = ({ PageInfo, children }) => {

    // Destructure pageInfo for better readability
    const { heading, imgClass, paragraphs, list_content, bgColors, dropshadow } = PageInfo;

    // Use functional components for Paragraphs and ListContent
    const Paragraphs = () => Object.entries(paragraphs).map(item_content => {
        const [key, value] = item_content;
        return <p key={key}>{value}</p>;
    });
    const ListContent = () => Object.entries(list_content).map(item_content => {
        const [key, value] = item_content;
        return <li key={key}>{value}</li>;
    });

    // console.log("Business? ", heading.toString().includes("Businesses"))

    return (
        <div className="pageheading-wrapper">
            <div className="pageheading-intro">
                <div className={`pageheading-bg-img ${imgClass}`} />
                <div className="pageheading-info" style={{backgroundColor: bgColors.secondary}}>
                    <h1 className={"mx-auto"}>{heading}</h1>
                    <Paragraphs />
                    <ul className="list-items">
                        <ListContent />
                    </ul>
                </div>
            </div>
            {children &&
                ( heading.toString().includes("Businesses") === false ?
                    (<div className="pageheading-body-container">
                        <div
                            className={`pageheading-body`}
                            style={{backgroundColor: bgColors.primary}}
                        >
                            <div
                                className={`pageheading-body-content mx-auto ${dropshadow}`}
                                style={{backgroundColor: bgColors.secondary}}
                            >
                                {children}
                            </div>
                        </div>
                    </div>) : (
                    <div className="pageheading-body-container">
                        <div
                            // className={`pageheading-body`}
                        >
                            {children}
                        </div>
                    </div>
                    )
            )}
        </div>
    );
};

export default PageHeading;
