import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import PageHeading from "../../../Components/PageHeading/PageHeading";
import './BusinessesPage.css';
import Gallery from "../../../Components/Gallery/Gallery";

const BusinessesPage = () => {
    const location = useLocation();

    useEffect(() => {
        // Scroll logic here, triggered on component mount or when the location changes
        const { hash } = location;
        const element = document.getElementById(hash.substring(1));
        if (hash) {
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);

    // Page details with initial children set to null
    const pageDetails = {
        imgClass: "businessesheading-bg-img",
        heading: "Businesses",
        bgColors: {
            // Blue and Yellow - Personal Coaching
            primary: "#EED570",
            secondary: "#377ed4"
        },
        paragraphs: {
            p1: "Welcome to our EAP! We help organizations of all sizes improve their employee well-being and performance."
        },
        list_content: {
            l1: "We understand that your staff is your most important asset.",
            l2: "We offer professional NLP coaching and consultation for emotional and mental wellness.",
            l3: "We work with organizations of all sizes and industries to create customized plans.",
            l4: "Let's chat to understand your unique needs and develop a relevant solution."
        },
        children: null, // Initially set to avoid potential errors
    };

    // Render PageHeading with pageDetails (including children)
    return (
        <React.Fragment>
            <PageHeading PageInfo={pageDetails} >
                <Gallery/>
            </PageHeading>
        </React.Fragment>
    );
};

export default BusinessesPage;
