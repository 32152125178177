import React from 'react';
import './Gallery.css'

const Gallery = () => {
    const imageHome = process.env.PUBLIC_URL + "/Assets/Images/CompanyLogos";

    const GALLERY_IMAGES = [
        {
            img: imageHome + "/condorgreen.png",
            heading: "Condorgreen",
            description: "Condorgreen"
        },
        {
            img: imageHome + "/cape_herb_and_spice.png",
            heading: "Cape Herb & Spice",
            description: "Cape Herb & Spice"
        },
        {
            img: imageHome + "/finchoice.png",
            heading: "Finchoice",
            description: "Finchoice"
        }
    ]

    return <div className="gallery-body">
            <p className="gallery-heading">Partnerships</p>
            <div className="gallery-images">
                {
                    GALLERY_IMAGES.map((image) => {
                        return <div className="gallery-image">
                            <div className="img-box">
                                <img src={image.img} alt=""/>
                                <div className="transparent-box">
                                    <div className="caption">
                                        <p>{image.heading}</p>
                                        <p className="opacity-low">{image.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
    </div>
}

export default Gallery;