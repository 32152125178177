import React from "react";
import './FAQ.css'

const FAQ = () => {
    const faq_info = [
        {
            key: 'a',
            heading: "Can I see a life coach even though I'm already on medication and/or seeing a psychologist/psychiatrist at the same time ?",
            content: {
                p1: "Yes you are able to see a life coach simultaneously , we are not doctors so we don’t do the same work as them and cannot treat a mental illness but we assist with your wellness and strengthen you in order for you to get to you back to your awesome best."
            }
        },
        {
            key: 'b',
            heading: "Can I pay for someone else to be life coached?",
            content: {
                p1: "Of course you may pay for a candidate, this has happened so many times before and is frequently the case. Parent for child , friend for friend ,helping a random person you may be compassionate about. The only need is that the person have given consent to do the sessions."
            }
        },
        {
            key: 'c',
            heading: "What is the youngest and oldest a person should be to have a life coach?",
            content: {
                p1: "any age is welcome, we have coached from as young as 8 years old but from our experience between the ages of 16 and 55 is the majority of those we assist."
            }
        },
        {
            key: 'd',
            heading: "Is anyone welcome to be coached?",
            content: {
                p1: "Absolutely anyone is welcome, one of core values are to be completely open and unbiased in our assistance. No matter your cultural difference , religion or beliefs   , sexuality or gender , race ,age or even location it makes no difference ,we are here to help you."
            }
        },
        {
            key: 'e',
            heading: "Are there a set amount of sessions to be done for the coaching to be completed?",
            content: {
                p1: "No, the amount of sessions are completely up to you , there is no agreement to how many sessions need to be done. Some people have had one session and found what they been looking for, others have been with us for 5 years and still have check in sessions up to today."
            }
        },
        {
            key: 'f',
            heading: "How does remote sessions work?",
            content: {
                p1: "Very simple , we send you a link via email on the platform easiest for us both- google meet ,Microsoft teams or even WhatsApp video call. This can be done via your phone or laptop ,if we can see you and hear you its all good. You can be at work or even in the comfort of your armchair at home ,we will be able to do the session."
            }
        },
        {
            key: 'g',
            heading: "How long in duration are each of the sessions ?",
            content: {
                p1: "60 minutes"
            }
        },
        {
            key: 'h',
            heading: "What are your operating hours ?",
            content: {
                p1: "We operate from 8 to 6pm Monday to Friday unless for emergency and special cases where we will make arrangements if possible."
            }
        },
        {
            key: 'i',
            heading: "Why do we need to do the 15min introduction call?",
            content: {
                p1: "For a few reasons , firstly that you or the candidate/s have complete consent to doing the coaching, another reason is so that we can build the necessary rapport to commence the coaching ( when speaking to us you will feel way more at ease to start )  ,  so that we can schedule the date and time for your first session/s as well as in order for you to have any other concerns or questions ironed out."
            }
        },
        {
            key: 'j',
            heading: "Is donating to your cause or sponsoring a candidate anonymously allowed?",
            content: {
                p1: "its not only allowed its welcomed , especially for the candidates (of which there are so many) that cant afford these amazing sessions. Your contributions are directly linked to our cause and passion of helping others. Contact us so we can help you help someone else.\n"
            }
        },
        {
            key: 'k',
            heading: "How many participants make up a team or group?",
            content: {
                p1: "We find that 8 to 15 participants works best for a great group session."
            }
        },
        {
            key: 'l',
            heading: "How does relationship coaching work ,is everyone in one session together at the same time?",
            content: {
                p1: "The journey of relationship coaching is made up of individual sessions for a while and eventually have sessions together."
            }
        },
        {
            key: 'm',
            heading: "Can I refer your EAP solution to my place of work ?",
            content: {
                p1: "Definitely, all our work has come from a referral from within the structure of the company itself. Please do so we will love to work with your company."
            }
        },
        {
            key: 'n',
            heading: "How long is group/ team coaching session?",
            content: {
                p1: "90 minutes"
            }
        }
    ]

    return (
        <React.Fragment>
            <div className="faq-body">
                <div className="faq-section">
                    <header className="faq-header">
                        <h2>FAQs</h2>
                        <p>Answers to the most frequently asked questions.</p>
                    </header>
                    {
                        faq_info.map((item, index) => {
                            const paraList = Object.entries(item.content).map(item_content => {
                                const [key, value] = item_content;
                                return <p key={`faq-${key}`}>{value}</p>;
                            });

                            return (
                                <React.Fragment key={`faq-${item.key}`}>
                                    <details className="faq-details">
                                        <summary>
                                            <h4>{item.heading}</h4>
                                            <span className="material-symbols-outlined">expand_more</span>
                                        </summary>
                                        {paraList}
                                    </details>
                                    {index < faq_info.length - 1 &&
                                        <hr/>}
                                </React.Fragment>
                            );
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export default FAQ;