import React from "react";
import {Link} from "react-router-dom";

import './SocialMediaNavbar.css'
import './FAQs.css'

const FAQs = ({ className }) => {
    const MobileView = className.toString().includes("greater");

    const handleClick = (e) => {
        if (MobileView) {
            const btn_element = document.getElementById("navbar-toggle");
            const nav_items = document.getElementById('navbarSupportedContent');

            btn_element.setAttribute("class", "navbar-toggler collapsed");
            btn_element.setAttribute("aria-expanded", "false");
            nav_items.setAttribute("class", "collapse navbar-collapse navbar-scroll");
        }
    };

    return (
        <nav className={`faq-social ${className}`}>
            <ul className="faq-social-list">
                <li>
                    <Link to="/faq" onClick={handleClick}>
                        {MobileView ? "FAQ" : <i className="fa-solid fa-lightbulb"></i>}
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default FAQs;