import React from "react";

import './AboutPage.css'
import Gallery from "../../../Components/Gallery/Gallery";

const AboutPage = () => {
    return (
        <React.Fragment>
            <Gallery/>
        </React.Fragment>
    );
}

export default AboutPage;