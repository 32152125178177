import React from "react";
// import {Link} from "react-router-dom";
import './SocialMediaNavbar.css'

const SocialMediaNavbar = ({className}) => {

    const handleOnclick = () => {
        // Validate phone number format (optional)
        // const isValid = /^\d+$/.test(phoneNumber); // Check for digits only
        //
        // if (!isValid) {
        //     console.error('Invalid phone number provided');
        //     return; // Prevent navigation on invalid number
        // }
        // const phoneNumber = "0788042478"

        // Construct the WhatsApp link with appropriate URL encoding
        // const encodedNumber = encodeURIComponent(phoneNumber);
        // const whatsappLink = `https://wa.me/${encodedNumber}`;
        window.location.href = "https://wa.me/message/SMAJWV7O2KFNO1"; // Open link in current tab
    };

    return (
        <nav className={`social ${className}`}>
            <ul className="social-list">
                <li>
                    <span onClick={() => handleOnclick()}>
                        <i className="fab fa-whatsapp"></i>
                        <div className="whatsappQR"></div>
                    </span>
                </li>
                <li>
                    <a href="https://www.facebook.com/whiteflagcoaching" target="_blank" rel="noopener noreferrer">
                        <i className="fa-brands fa-facebook-f"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/white-flag-coaching-npc-b33b291b3/?originalSubdomain=za"
                          target="_blank" rel="noopener noreferrer">
                        <i className="fa-brands fa-linkedin"></i>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/whiteflagcoach" target="_blank" rel="noopener noreferrer">
                        <i className="fa-brands fa-twitter"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/whiteflag_coaching/" target="_blank" rel="noopener noreferrer">
                        <i className="fa-brands fa-instagram"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.tiktok.com/@whiteflagcoaching" target="_blank" rel="noopener noreferrer">
                        <i className="fa-brands fa-tiktok"></i>
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default SocialMediaNavbar;