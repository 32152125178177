import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

import PageHeading from "../../../Components/PageHeading/PageHeading";
import './IndividualPage.css'
import Checklist from "../../../Components/Checklist/Checklist";

const IndividualPage = () => {
    const location = useLocation();

    useEffect(() => {
        // Scroll logic here, triggered on component mount or when the location changes
        const { hash } = location;
        const element = document.getElementById(hash.substring(1));
        if (hash) {
            if (element) {
                element.scrollIntoView({ behavior: 'smooth',  });
            }
        }
    }, [location]);

    let pageDetails = {
        imgClass: "individualheading-bg-img",
        bgColors: {
            // Blue and Yellow - Personal Coaching
            primary: "#EED570",
            secondary: "#377ed4"
        },
        heading: "Welcome to White Flag Personal Coaching!",
        dropshadow: "individual-dropshadow",
        paragraphs: {
            p1: "Hi there!  Welcome to your personal growth journey with White Flag Coaching. This is a powerful step you're taking, and we applaud your courage!",
            p2: "Life's challenges can get us stuck and feeling lost.  We believe these moments hold the potential for transformation.",
            p3: "At White Flag, we offer a safe, supportive space free from judgment.  Our experienced coaches will guide you with patience and expertise to help you grow, develop, and thrive.",
            p4: "Ready to embrace your free, healthy, and confident self? Let's go!",
        },
        list_content: {}
    }

    const checklist_items = [
        {
            heading: "Depression",
            content: "You feeling overwhelmed with sadness, experiencing sleep deprivation,  feeling emotionally and mentally burnt out."
        },
        {
            heading: "Anxiety",
            content: "You struggling to face the future with any confidence, a fearful state which causes you to doubt yourself about executing what needs to be done."
        },
        {
            heading: "Loneliness",
            content: "You isolated with few or no people to speak or connect to."
        },
        {
            heading: "Self discovery",
            content: "You have the need to know who you are and what value you have."
        },
        {
            heading: "Lack of confidence",
            content: "Your self-belief is limited, you need to feel empowered about who your are and what you able to do."
        },
        {
            heading: "Indecisiveness",
            content: "You feel in 2 minds ,you struggling to make the “right” decision. You feel uncertain and confused at this point of your life. You procrastinate."
        },
        {
            heading: "Purposelessness",
            content: "You feel that you have little or no value as a person ,asking why are you here and what you are here for."
        },
        {
            heading: "Disconnected",
            content: "Feeling like you are not fitting in, displaced from everywhere and everyone"
        },

        {
            heading: "Demotivated",
            content: "You struggle to find the motivation to commit or continue with the task at hand."
        },
        {
            heading: "Going through divorce or separation",
            content: "You struggling with the emotional and metal turmoil of this unfortunate process."
        },
        {
            heading: "In need of guidance or mentorship",
            content: "Whether in your career or general life a source of safe keeping and belief."
        },
        {
            heading: "Grief or loss assistance",
            content: "You are losing have lost a companion, friend or family member and never processed this difficult situation."
        },
        {
            heading: "Trauma assistance",
            content: "You struggle live “normally” as result of a traumatic experience or event."
        },

        {
            heading: "Awareness assistance",
            content: "You finding new truths about yourself and life, you have many questions and need to process this new life understandings."
        },
        {
            heading: "Feeling disorganized",
            content: "Your life feels chaotic and all over the place, you in need of order. A plan and strategy will help you at this time. Lack of time management."
        },
        {
            heading: "Communication skills development",
            content: "You struggle to communicate clearly and concisely ,whether verbal or in writing."
        },
        {
            heading: "Feeling conflicted",
            content: "Very difficult situation or a relationship and a confrontation or resolution needed."
        },
        {
            heading: "Debrief",
            content: "Just need to vent or have the need to talk, have the need to be listened to."
        },

        {
            heading: "Feeling drained",
            content: "Your energy is really low or depleted."
        },
        {
            heading: "Procrastination",
            content: "You struggle to complete or even begin projects and tasks on time. Time management skills need improvement."
        },
        {
            heading: "Chronic illness depression",
            content: "Whether cancer, diabetes or broken limb you can feel demotivated and helpless."
        },
        {
            heading: "Imposter syndrome",
            content: "Feeling anxious and not experiencing success internally, despite being high-performing in external, objective ways."
        },
        {
            heading: "Lack of boundaries",
            content: "You struggle to say no , feel you able to taken advantage of ."
        },
        {
            heading: "Other",
            content: "Any symptom not mentioned above. Please elaborate if possible ."
        }
    ]

    return (
        <React.Fragment>
            <PageHeading PageInfo={pageDetails}>
                <h4 className={"mx-auto"} style={{marginBottom: "-50px", marginTop: "20px", color: "white"}}>
                    Choose from the list below in order for us to have an idea of what your coaching journey would need to focused on. On receipt of your symptoms and conditions that is sent via email to us we would set up a free intro call (15min ) to discuss the way forward . Lets go!
                </h4>
                <Checklist ChecklistItems={checklist_items} Heading={"Symptoms and conditions you may be experiencing"}/>
            </PageHeading>
        </React.Fragment>
    );
}

export default IndividualPage;